@charset "utf-8";

/* -- 게시판 공통 -- */
#board {
  position: relative;
  width: 100%;
}

#board .web_col {
  display: table-column-group;
}
#board .mob_col {
  display: none;
}
#board .web_td {
  display: table-cell;
}
#board .mob_td {
  display: none;
}
@media (max-width: 991px) {
  #board table th,
  #board table td {
    padding: 15px 5px;
  }

  #board .web_col {
    display: none;
  }
  #board .mob_col {
    display: table-column-group;
  }
  #board .web_td {
    display: none;
  }
  #board .mob_td {
    display: table-cell;
  }
}

/* 게시판 제목 말줄임 */
#board .subject {
  display: inline-block;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#board .subject2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2em;
  height: 2.4em;
  overflow: hidden;
}
#board .subject3 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
}

/* -- 비밀번호 팝업 -- */
#board_pw .modal-body {
  padding: 3rem;
}
#board_pw .pw_title {
  padding: 0 0 1rem 0;
  text-align: center;
}
#board_pw a {
  display: block;
  height: 40px;
  line-height: 37px;
  text-align: center;
  border-radius: 5px;
}
#board_pw input {
  border-radius: 5px;
}

/* -- 글갯수 -- */
#board .total {
  padding: 0 2px 10px 0;
  text-align: left;
  line-height: 1em;
}
@media (max-width: 991px) {
  #board .total {
    text-align: right;
  }
}

/* -- 개시물 없음 -- */
.no_data {
  padding: 10% 0;
}
.no_data i {
  font-size: 3em;
}
.no_data p {
  padding: 10px 0 0 0;
}

/* -- 첨부파일 -- */
.filebox {
  overflow: hidden;
}
.filebox .upload-name {
  vertical-align: middle;
}
.filebox label {
  padding: 0;
}
.filebox label i {
  display: block;
  height: 40px;
  line-height: 38px;
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
}

/* -- 버튼 -- */
.btn_wrap {
  padding: 1% 0 0 3%;
  margin-bottom: 10px;
  overflow: hidden;
}
.btn_wrap .fl_center {
  text-align: center;
}
.btn_wrap .fl_left {
  text-align: left;
}
.btn_wrap .fl_right {
  text-align: right;
}
.btn_wrap a {
  display: inline-block;
  height: 40px;
  margin: 0 3px;
  padding: 0 50px;
  vertical-align: middle;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}
.btn_wrap input[type='button'],
.btn_wrap input[type='reset'] {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  padding: 0 50px;
  margin: 0 5px 0 5px;
  line-height: 40px;
  border-radius: 50px;
}

/* 버튼 색상 */
.bt_main {
  color: #fff !important;
  background: #0f3164;
}
.bt_gray {
  color: #fff !important;
  background: #808080;
}

/* -- 리스트 -- */
#board .list {
  border-top: 2px solid #f1f1f1;
}
#board .list th,
#board .list td {
  padding: 15px 10px;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #f1f1f1;
}
#board .list th {
  background: #fafafa;
}
#board .list .icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../img/common/icon_notice.png) no-repeat center 0, #0f3164;
  border-radius: 50px;
  overflow: hidden;
}

/* -- 보기 -- */
#board .view th {
  padding: 15px 10px;
  text-align: center;
  font-weight: 500;
  border-top: 2px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  background: #fafafa;
}
#board .view td {
  padding: 15px 10px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
}
#board .view .date {
  font-size: 0.9em;
  color: rgba(108, 102, 97, 0.5);
  text-align: right;
}
#board .view .aut {
  font-size: 0.9em;
  color: rgba(108, 102, 97, 1);
  text-align: left;
}

#board .view .con {
  text-align: left;
}
#board .view .con img {
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

/* -- 블로그 -- */
#board .blog {
  margin: 0 -10px;
}
#board .blog li {
  float: left;
  width: 33.3%;
  padding: 10px;
}

#board .blog a {
  display: block; /*border: 1px solid #e5e5e5;*/
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0.2s;
}
#board .blog li:hover a {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#board .blog .text {
  padding: 20px;
}
#board .blog .text h5 {
  margin: 10px 0;
}
@media (max-width: 991px) {
  #board .blog li {
    width: 100%;
  }
}

/* -- 페이징 -- */
#page {
  padding: 3% 0 0 0;
  text-align: center;
  clear: both;
}
#page span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  color: rgba(108, 102, 97, 0.8);
}
#page span:hover {
  color: #0f3164;
}
#page .on {
  border-radius: 50%;
  color: #fff;
  background: #0f3164;
}
#page .on:hover {
  color: #fff;
}
#page .first,
#page .prev,
#page .next,
#page .last {
  width: 20px;
  height: 40px;
  background: url('../img/common/arrow.png') no-repeat;
  vertical-align: top;
}
#page .first {
  background-position: -40px 8px;
}
#page .prev {
  background-position: 0px 8px;
  margin: 0 2% 0 0;
}
#page .next {
  background-position: -20px 8px;
  margin: 0 0 0 2%;
}
#page .last {
  background-position: -60px 8px;
}

/* -- 검색 -- */
.board_search {
  width: 45%;
  margin: 0 auto;
  padding: 3% 0 0 0;
  text-align: center;
}

.board_search ul {
  font-size: 0;
}
.board_search ul li {
  display: inline-block;
  width: 22%;
  padding: 0 1px;
  margin: 0 2px 0 2px;
  font-size: 16px;
  height: 75%;
  vertical-align: middle;
}
.board_search ul li > div {
  height: 40px;
}
.board_search ul li:nth-child(2) {
  width: 40%;
}
.board_search input,
.board_search select {
  border-radius: 5px;
}

@media (max-width: 991px) {
  .board_search {
    width: 95%;
    margin: 0 auto;
  }
  .board_search ul li {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .board_search ul li {
    font-size: 12px;
  }
}
