@charset "UTF-8";

.box01 {
  padding: 5% 0;
  text-align: center;
}
.box01 h1 {
  line-height: 1.5em;
}
.box01 h2 {
  padding: 3% 0 7% 0;
  line-height: 1.5em;
}
.box01 strong {
  font-weight: 800;
}
.box01 img {
  width: auto;
}
.box01 li {
  text-align: center;
}
.box01 li h4 {
  padding: 7% 0 0 0;
}
.q_btn {
  padding: 7% 0 0 0;
}
.q_btn a {
  display: inline-block;
  padding: 0 5%;
  line-height: 70px;
  color: #fff !important;
  border: 3px solid #0f3164;
  border-radius: 70px;
  background: #0f3164;
}
.q_btn a:hover {
  color: #0f3164 !important;
  background: #fff;
}
@media (max-width: 991px) {
  .q_btn a {
    padding: 0 10%;
    line-height: 50px;
  }
  .box01 img {
    width: 80px;
  }
  .box01 ul {
    border: 1px solid #e5e5e5;
  }
  .box01 li {
    padding: 7% 0 0 0;
    border: 1px solid #e5e5e5;
  }
  .box01 li h4 {
    padding: 7% 0;
  }
}

.box02 {
  position: relative;
  padding: 5% 0 7% 0;
  background: #d2d8e3;
}
.box02 h1 {
  padding: 0 0 5% 0;
  text-align: center;
  font-weight: 800;
}
.box02 .subject2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.2em;
  height: 2.4em;
  overflow: hidden;
}
.box02 .subject3 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 1.5em;
  height: 4.5em;
  overflow: hidden;
}
.box02 h5 {
  margin: 10px 0;
  font-weight: 400;
}
.p_slider a {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
}
.p_slider .text {
  padding: 20px;
  background: #fff;
}
.p_slider .text span {
  display: block;
  font-size: 12px;
  line-height: 1em;
}
.p_slider .text p {
  color: #828282;
}
@media (max-width: 991px) {
  .box02 h5 {
    margin: 5px 0;
  }
  .box02 .subject3 {
    line-height: 1em;
    height: 1em;
  }

  .p_slider .img {
    float: left;
    width: 50%;
  }
  .p_slider .text {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .bx-wrapper {
    position: initial;
  }
  .bx-wrapper .bx-controls-direction a {
    top: 8%;
    margin: 0;
  }
  .bx-wrapper .bx-prev {
    left: auto;
    right: 40px;
    border: 1px solid #fff;
  }
  .bx-wrapper .bx-next {
    right: 10px;
    border: 1px solid #fff;
  }
}

/*슬라이더*/
.m_wrap .bx-wrapper .bx-pager,
.m_wrap .bx-wrapper .bx-controls-auto {
  top: 10px;
  bottom: auto;
  padding: 0 1rem;
  text-align: right;
}
.m_wrap .bx-wrapper img {
  width: 100%;
}

.m_wrap {
  position: relative;
}
.m_wrap .scroll_wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  z-index: 50;
  width: 30px;
  height: 50px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100px;
}
.m_wrap .scroll {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background: #fff;
  animation-name: scroll;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
  animation-iteration-count: infinite;
  transform-origin: 50% 20.5px;
  will-change: transform, opacity;
  opacity: 1;
}

@keyframes scroll {
  0%,
  20% {
    transform: translateY(0) scaleY(1);
  }

  100% {
    transform: translateY(36px) scaleY(2);
    opacity: 0;
  }
}

/*팝업창*/
.pc_popup {
  font-size: 12px;
  z-index: 20 !important;
}
.pc_popup input {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.pc_popup label {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.pc_popup .close {
  font-size: 1em;
  margin-left: 5px;
  font-weight: 500;
  text-shadow: none;
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  height: 27px;
}
.pc_popup p {
  margin: 0;
  padding: 0;
}
.pc_popup .modal-content {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.pc_popup .modal-footer {
  border: 0;
  padding: 5px 10px;
}
.mobile_popup {
  display: none;
}

@media (max-width: 767px) {
  .pc_popup {
    display: none;
  }
  .mobile_popup {
    display: block;
    z-index: 20 !important;
  }
  .mobile_popup {
    top: 49px;
    left: 0;
    right: 0;
    display: block;
    font-size: 12px;
  }
  .mobile_popup .vertical-alignment-helper {
    max-width: 320px;
    margin: 0 auto;
  }
  .mobile_popup p {
    margin: 0;
    padding: 0;
  }
  .mobile_popup p img {
    width: 100% !important;
    height: auto !important;
  }
  .mobile_popup .modal-content {
    border: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .mobile_popup .modal-footer {
    border: 0;
    background: #fff;
  }
  .mobile_popup input {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
  }
  .mobile_popup label {
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
    font-family: "Nanum", sans-serif;
    margin: 0;
    padding: 0;
  }
  .mobile_popup .close {
    font-family: "Nanum", sans-serif;
    font-size: 1em;
    margin-left: 5px;
    font-weight: 500;
    text-shadow: none;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
  }
}
