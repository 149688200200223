@charset "utf-8";
@import './board.css';
@import './bootstrap-theme.css';
@import './bootstrap-theme.min.css';
@import './bootstrap.css';
@import './bootstrap.min.css';
@import './font.css';
@import './info.css';
@import './layout.css';
@import './main.css';
@import './member.css';
@import './menu.css';
@import './product.css';
@import './recruit.css';
@import './xeicon.css';
@import './slider.css';
/* Tag Definition */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
input,
button,
textarea,
select,
table {
  font-family: inherit;
  font-size: 1em;
  font-weight: 300;
}
label,
input,
button,
textarea,
select,
option {
  font-size: 1em;
  color: inherit;
  font-weight: 300;
}

select {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  line-height: 37px;
  border: 1px solid #e5e5e5;
  background: #fff;
  border-radius: 0px; /* iOS 둥근모서리 제거 */
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  vertical-align: middle;
  transition: all 0.3s ease 0s;
}
select:focus {
  border: 1px solid #7a6a56;
  outline: none;
}

label {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
input {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 37px;
  padding: 0 10px;
  vertical-align: middle;
  color: inherit;
  border: 1px solid #e5e5e5;
  background: #fff;
  transition: all 0.3s ease 0.2s;
}
input:focus {
  border: 1px solid #7a6a56;
  outline: none;
}
input[readonly='readonly']:focus {
  border: 1px solid #e5e5e5;
  outline: none;
}
input[type='text'] {
  border-radius: 4px;
}
input[type='checkbox'],
input[type='radio'],
input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  display: inherit;
  width: 1px;
  height: 1px;
  opacity: 0;
}
input[type='checkbox'] + i {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 1px solid #aaa;
  border-radius: 5px;
  background: #fff;
}
input[type='checkbox']:checked + i {
  border: 1px solid #7a6a56;
}
input[type='checkbox']:checked + i:before {
  content: '\e928';
  font-family: 'xeicon';
  display: block;
  text-align: center;
  line-height: 18px;
  color: #7a6a56;
}

input[type='radio'] + i {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 1px solid #aaa;
  border-radius: 20px;
  background: #fff;
}
input[type='radio']:checked + i {
  position: relative;
  border: 1px solid #7a6a56;
}
input[type='radio']:checked + i:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  display: block;
  background: #7a6a56;
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

textarea {
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  resize: none;
  vertical-align: middle;
  padding: 10px;
  line-height: 1.5em;
  transition: all 0.3s ease 0.2s;
}
textarea:focus {
  border: 1px solid #7a6a56;
  outline: none;
}

table {
  font-size: 1em;
} /* DOCTYPE 없으면 속성 상속 안됨 */
table,
td,
th {
  table-layout: fixed;
  border-collapse: collapse;
  text-indent: 0;
  width: 100%;
  border-spacing: 0;
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
fieldset,
iframe {
  border: 0;
  vertical-align: middle;
}
img {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
}
ul,
ul li,
ol,
ol li {
  list-style: none;
  padding: 0;
  margin: 0;
}
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}
p,
i,
b,
strong {
  margin: 0;
  padding: 0;
  line-height: 1.7em;
  font-weight: 300;
  font-style: normal;
}
sup,
sub {
  font-size: 1em;
  vertical-align: middle;
}
pre /* 형식 유지 컨텐츠 자동개행 */ {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  line-height: 1.7em;
  white-space: pre-wrap; /* css3 */
  white-space: -moz-pre-wrap; /* Mozilla, since1999 */
  white-space: -pre-wrap; /* Op4-6 */
  white-space: -o-pre-wrap; /* Op7 */
  word-wrap: break-word; /* IE 5.5+ */
}
