@charset "utf-8";

/* 노토 */
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 100;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Thin-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Thin-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Thin-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 200;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Light-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Light-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Light-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 300;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-DemiLight-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-DemiLight-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-DemiLight-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 400;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Regular-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Regular-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Regular-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 500;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Medium-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Medium-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Medium-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 700;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Bold-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Bold-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Bold-Hestia.otf") format("opentype");
}
@font-face {
  font-family: "noto";
  font-style: normal;
  font-weight: 800;
  src: local(¡Ø),
    url("../fonts/NotoSansKR-Black-Hestia.eot") format("embedded-opentype"),
    url("../fonts/NotoSansKR-Black-Hestia.woff") format("woff"),
    url("../fonts/NotoSansKR-Black-Hestia.otf") format("opentype");
}

/* @font-face {
  font-family: "Tahoma";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Tahoma.eot");
  src: local("sdf51"), local("sdf51"),
    url("../fonts/Tahoma.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Tahoma.woff") format("woff"),
    url("../fonts/Tahoma.otf") format("opentype");
} */
