@charset "UTF-8";

.company li:nth-child(3),
.company li:nth-child(4) {
  padding-top: 4%;
  padding-bottom: 4%;
}
.company h1 {
  position: relative;
  padding: 15px 0 0 0;
  margin-left: 15%;
}
.company h1:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 2px;
  background: #0f3164;
}
.company h2 {
  font-size: 2.1em !important;
  position: relative;
  padding: 10px 0 0 0;
  margin-left: 15%;
}

.company h2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 2px;
  background: #0f3164;
}
.company p {
  padding: 15px 0 0 0;
}

.company dl dt,
.company dl dd {
  padding-top: 30px;
}
.company dl strong {
  display: block;
  padding: 0 0 5px 0;
  font-weight: 500;
}
.company dl dt {
  text-align: center;
}
.company dl img {
  width: auto;
}

@media (max-width: 991px) {
  .company h1 {
    margin-left: 0;
  }
  .company h2 {
    margin-left: 0;
  }
  .company dl dt {
    text-align: left;
  }
  .company li:nth-child(3),
  .company li:nth-child(4) {
    padding-top: 0;
    padding-bottom: 0;
  }
  .company li:nth-child(2n) {
    padding-top: 0;
    padding-bottom: 10%;
  }
  .company li:nth-child(6) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.group{ padding-bottom: 10%; }
.group li{ position: relative; padding: 3% 0; }
.group li h3{ font-weight: 500; }
.group li p{ padding: 10px 0 0 0; text-transform: uppercase; }

.group li:after{ content: ""; position: absolute; left: 0; bottom: -10px; width: 100%; height: 5px; }
.group li:before{ content: ""; position: absolute; left: 0; bottom: -20px; width: 100%; height: 5px; }
.group .bg01{ text-align: center; color: #fff; background: #f2ae23; }
.group .bg02{ text-align: center; color: #fff; background: #20978a; }
.group .bg01:after{ background: #f2ae23; }
.group .bg01:before{ background: #f2ae23; }
.group .bg02:after{ background: #20978a; }
.group .bg02:before{ background: #20978a; }
