@charset "UTF-8";

.box01{ padding: 0 0 5% 0; text-align: center; }
.box01 h1{ line-height: 1.5em; }
.box01 h2{ padding: 3% 0 7% 0; line-height: 1.5em; }
.box01 strong{ font-weight: 800; }
.box01 img{ width: auto; }
.box01 li{ text-align: center; }
.box01 li h4{ padding: 7% 0 0 0; }
.q_btn { padding: 7% 0 0 0; }
.q_btn a{ display: inline-block; padding: 0 5%; line-height: 70px; color: #fff; border: 3px solid #0f3164; border-radius: 70px; background: #0f3164; }
.q_btn a:hover{ color: #0f3164; background: #fff; }
@media (max-width: 991px){
	.q_btn a{ padding: 0 10%; line-height: 50px; }
	.box01 img{ width: 80px; }
	.box01 ul{ border: 1px solid #e5e5e5; }
	.box01 li{ padding: 7% 0 0 0; border: 1px solid #e5e5e5; }
	.box01 li h4{ padding: 7% 0; }
}

.box02{ padding: 5% 0; text-align: center; background: #f4f5f5; }
.box02 img{ width: 80%; }

.box03{ padding: 5% 0; text-align: center; }
.box03 img{ width: 80%; }
@media (max-width: 991px){
	.box02 img, .box03 img{ width: 100%; }
}

.box04{ padding: 0; }
.box04 .img{ padding-bottom: 7%; }
.box04 h2{ padding-top: 5%; font-weight: 500; }
.box04 img{ width: auto; }
.box04 h1{ padding: 0 0 5% 0; text-align: center; font-weight: 800; }
.box04 li > div{ padding: 5%; text-align: center; border: 1px solid #e6e6e7; border-top: 2px solid #0f3164; }
@media (max-width: 991px){
	.box04 img{ width: 150px; }
	.box04 li:nth-child(1) { padding-bottom: 15px; }
}

.learning .box01{ padding: 0 0 5% 0; text-align: center; }
.learning .box01 h2{ padding: 3% 0; line-height: 1.5em; }
.learning .box01 strong{ font-weight: 800; }
.learning .box01 img{ width: auto; }
.learning .box01 ul{ width: 80%; margin: 0 auto; }
.learning .box01 li{ padding-top: 7%; text-align: center; }
.learning .box01 li h4{ padding: 7% 0 0 0; text-transform: uppercase; }
@media (max-width: 991px){
	.learning .box01{ padding: 0 0 15% 0; }
}
.learning .box02 dl{ overflow: hidden; }
.learning .box02 dt{ width: 40%; padding: 40px 30px; text-align: center; }
.learning .box02 dt h3{ padding: 0 0 5% 0; }
.learning .box02 dt h4{ line-height: 1.5em; }
.learning .box02 dt h5{ line-height: 1.5em; }
.learning .box02 dt h6{ font-weight: 500; line-height: 1.5em; }
.learning .box02 dt p{ position: relative; padding: 0 0 0 10px; text-align: left; }
.learning .box02 dt p:before{ content: ""; position: absolute; top: 12px; left: 0; width: 5px; height: 2px; background: #00a1b0; }

.learning .box02 .pd_t7{ padding-top: 5%; }
.learning .box02 .pd_b7{ padding-bottom: 5%; }
.learning .box02 .bold{ font-weight: 700; }
.learning .box02 .img img{ width: auto; }

.learning .box02 dd{ width: 60%; padding: 30px 40px; }
.learning .box02 dd h2{ padding: 0 0 3% 0;  font-weight: 800; text-transform: uppercase; }
.learning .box02 li{ position: relative; padding: 5px 30px; line-height: 1.7em; }
.learning .box02 li i{ position: absolute; top: 10px; left: 0; display: block; width: 20px; height: 20px; border-radius: 20px; line-height: 20px; text-align: center; color: #fff; background: #00a1b0; }

.learning .box02{ padding: 0 0 5% 0; }
.learning .box02 .bg01{ background: url("../../assets/img/02_product/02_06.png") 98% 98% no-repeat , #f0f0f0; }
.learning .box02 .bg02{ background: url("../../assets/img/02_product/02_07.png") 98% 98% no-repeat , #f0f0f0; }
.learning .box02 .bg03{ background: url("../../assets/img/02_product/02_08.png") 98% 98% no-repeat , #f0f0f0; }
.learning .box02 .bg04{ background: url("../../assets/img/02_product/02_09.png") 98% 98% no-repeat , #f0f0f0; }
@media (max-width: 991px){
	.learning .box02 dt{ display: block; width: 100%; }
	.learning .box02 dd{ display: block; width: 100%; }
}

.learning .box03{ padding: 3% 0; background: #f1f2f3; }
.learning .box03 img{ width: auto; }
.learning .box03 table{ overflow: hidden; }
.learning .box03 td{ position: relative; width: 25%; padding: 40px 35px; vertical-align: top; text-align: center; border-left: 1px solid #ccc; }
.learning .box03 td:nth-child(1){ border: 0; }
.learning .box03 td:nth-child(1):before{ display: none; }
.learning .box03 td:before{ content: "\e93f"; font-family: "xeicon"; position: absolute; left: -20px; top: 50%; width: 40px; height: 40px; line-height: 40px; font-size: 1.3em; text-align: center; color: #fff; border-radius: 50px; background: #015570; }

.learning .box03 li:before{ content: ""; position: absolute; top: 0; left: 0; width: 1px; height: 100%; background: #ccc; }
.learning .box03 h5{ padding: 30px 0 20px 0; font-weight: 500; line-height: 1.3em; letter-spacing: -1px; }
.learning .box03 h6{ position: relative; padding: 0 0 0 10px; text-align: left; line-height: 1.3em; }
.learning .box03 h6:before{ content: ""; position: absolute; top: 12px; left: 0; width: 5px; height: 2px; background: #00a1b0; }
@media (max-width: 991px){
	.learning .box03 td{ display: block; width: 100%; border-left: 0; border-top: 1px solid #ccc; }
	.learning .box03 td:before{ content: "\e942"; font-family: "xeicon"; left: 45%; top: -20px; z-index: 10; }
}

.learning .box04{ padding: 5% 0 3% 0; }
.learning .box04 img{ width: auto; }

.learning .box05 td{ width: 50%; padding: 3%; color: #fff; background: #015570; overflow: hidden; }
.learning .box05 th{ width: 50%; padding: 3%; color: #fff; background: #00a1b0; overflow: hidden; }
.learning .box05 img{ width: auto; }
.learning .box05 i{ display: block; float: left; width: 20%; }
.learning .box05 p{ float: left; width: 80%; line-height: 1.5em; }
@media (max-width: 991px){
	.learning .box05 th{ display: block; width: 100%; }
	.learning .box05 td{ display: block; width: 100%; }
	.learning .box05 i{ width: 20%; padding: 0 25px 0 0; }
	.learning .box05 p{ width: 80%; }
}