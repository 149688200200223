@charset "utf-8";

/* -- 로그인 -- */
.find {
  padding: 30px 0;
  overflow: hidden;
}
.find ul li {
  float: left;
  width: 33.3%;
  text-align: center;
  border: 1px solid #eee;
  border-left: 0;
  background: #f7f7f7;
}
.find ul li:nth-child(1) {
  border-left: 1px solid #eee;
}
.find ul li a {
  display: block;
  padding: 10px 0;
}
.login .h3 {
  padding: 0 0 15px 0;
}
.login_sns .h3 {
  padding: 0 0 15px 0;
}
.login_sns li {
  padding-bottom: 20px;
}
.login_sns li p {
  border-radius: 6px;
}
.login_sns li a {
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.login_sns li i {
  padding: 0 5% 0 0;
}

.join_btn {
  text-align: center;
}
.join_btn i {
  border-bottom: 1px solid #333;
}
@media (max-width: 991px) {
  .find {
    padding: 20px 0 10px 0;
  }
  .join_btn {
    padding: 0 0 20px 0;
  }
  .login_sns li {
    padding: 5px 0;
  }
  .login_sns li a {
    height: 50px;
    line-height: 50px;
  }
}

.form-group dt {
  font-weight: 300;
  line-height: 40px;
}
.form-group a {
  display: block;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
}
.form-group > p {
  padding: 10px 0 20px 0;
  border-top: 2px solid #4254f3;
}
.form-group > p strong {
  font-size: 0.9em;
  font-weight: 500;
  color: #4254f3;
}
.form-group .terms_con {
  height: 250px;
  padding: 0 10px;
  border: 1px solid #ddd;
  overflow-y: scroll;
}
.form-group label {
  padding: 10px 0 0 0;
}
.form-group span {
  display: block;
  color: #999;
}
