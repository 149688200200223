@charset "UTF-8";

.recruit h1{ padding: 5% 0 3% 0 }
.recruit h1:nth-child(1){ padding: 0 0 3% 0 }
.recruit th{ padding: 10px; text-align: center; color: #fff; background: #00a1b0; }
.recruit th:nth-child(1){ border-right: 10px solid #fff; }
.recruit td{ padding: 20px; text-align: center; border-bottom: 2px solid #0f3164; }
.recruit td:nth-child(1){ border-right: 10px solid #fff; }
@media (max-width: 991px){
	.q_btn a{ padding: 0 10%; line-height: 50px; }
	.box01 img{ width: 80px; }
	.box01 ul{ border: 1px solid #e5e5e5; }
	.box01 li{ padding: 7% 0 0 0; border: 1px solid #e5e5e5; }
	.box01 li h4{ padding: 7% 0; }
}