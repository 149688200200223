@charset "utf-8";
html {
  font-size: 16px;
}
body {
  width: 100%;
  height: 100%;
  font-family: 'noto', 'Dotum', '돋움', 'sans-serif';
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  word-break: keep-all;
  word-wrap: break-word;
  color: #000;
}
.tahoma {
  font-family: 'Tahoma';
}
.web {
}
.mob {
  display: none;
}

@media (max-width: 991px) {
  html,
  body {
    font-size: 14px;
  }
  .wrap {
    padding: 50px 0 0 0;
  }
  .web {
    display: none;
  }
  .mob {
    display: block;
  }
}

@media (max-width: 767px) {
  html,
  body {
    font-size: 12px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  font-weight: 300;
}
h1,
.h1 {
  font-size: 2.5em !important;
} /*40*/
h2,
.h2 {
  font-size: 1.8em !important;
}
h3,
.h3 {
  font-size: 1.5em !important;
} /*24*/
h4,
.h4 {
  font-size: 1.38em !important;
} /*22*/
h5,
.h5 {
  font-size: 1.25em !important;
} /*20*/
h6,
.h6 {
  font-size: 1.12em !important;
} /*18*/

/* 컨텐츠 공통*/
.con_pd {
  padding-top: 50px;
  padding-bottom: 70px;
}
@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 1.9em;
  } /*40*/
  .con_pd {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

/*기준색*/
.main_co {
  color: #0f3164 !important;
}
.sub_co {
  color: #00a1b0 !important;
}
.point_co {
  color: #0ab725 !important;
}

.main_bg {
  color: #fff !important;
  background: #0f3164 !important;
}
.main_bg.top {
  display: block;
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  line-height: 1em;
  padding: 9px 0;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.sub_bg {
  color: #fff !important;
  background: #00a1b0 !important;
}
.point_bg {
  color: #fff !important;
  background: #0ab725 !important;
}

.gray_bg {
  background: #f5f5f5 !important;
}
.line_bg {
  border: 1px solid #555;
}

.kakao_bg {
  color: #361d1d;
  background: #ffd325;
}
.naver_bg {
  color: #fff;
  background: #34b833;
}
.facebook_bg {
  color: #fff;
  background: #3150b3;
}

.red_co {
  color: #ff8989 !important;
}

.info .hero {
  background: url(../img/common/hero_01.png) center no-repeat;
}
.product .hero {
  background: url(../img/common/hero_02.png) center no-repeat;
}
.board .hero {
  background: url(../img/common/hero_03.png) center no-repeat;
}
.query .hero {
  background: url(../img/common/hero_04.png) center no-repeat;
}
.recruit .hero {
  background: url(../img/common/hero_05.png) center no-repeat;
}
.privacy .hero {
  background: url(../img/common/hero_06.png) center no-repeat;
}

.hero {
  position: relative;
  height: 250px;
}
.hero p {
  position: absolute;
  bottom: 50px;
  line-height: 1em;
  color: #fff;
}
.hero strong {
  font-weight: 500;
}
@media (max-width: 991px) {
  .hero {
    padding: 5rem 0;
    height: auto;
  }
  .hero p {
    bottom: 10px;
  }
  .info .hero,
  .product .hero,
  .board .hero,
  .query .hero,
  .recruit .hero,
  .privacy .hero {
    background-size: cover;
  }
}

/* 약관 모달 */
.modal-body .scroll {
  height: 550px;
  overflow-y: scroll;
}
.modal .close {
  left: auto;
  right: 0;
}

/* -- 약관 -- */
.terms ul p {
  line-height: 30px;
}

.terms .con {
  padding: 15px 10px;
  border-bottom: 1px solid #e5e5e5;
}
.terms .scroll {
  height: 100px;
  padding: 15px;
  border: 1px solid #e5e5e5;
  overflow-y: scroll;
}
.terms .ok {
  padding-top: 15px;
}

.terms ul li {
  position: relative;
}
.terms .list01 li {
  padding: 0 0 0 25px;
  margin-top: 20px;
}

.terms .list02 li {
  padding: 0 0 0 35px;
}
.terms .list02 i {
  position: absolute;
  left: 10px;
}
.terms .list03 li {
  padding: 0 0 0 30px;
}
.terms .list03 i {
  position: absolute;
  left: 10px;
}

.terms table {
  border-top: 2px solid #15aae8;
}
.terms table th {
  font-weight: 300;
  background: #eee;
}
.terms table th,
.terms table td {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.terms .tit {
  padding: 15px 0 0 0;
  font-weight: 500;
}
.terms .sub_tit {
  padding: 15px 0 5px 0;
  font-weight: 500;
}
/*.terms .sub_tit:before{ content: "■ "; }*/
.terms p {
  padding: 5px 5px 0 5px;
}

.terms .circle > li:nth-child(1) > i:before {
  content: '①';
}
.terms .circle > li:nth-child(2) > i:before {
  content: '②';
}
.terms .circle > li:nth-child(3) > i:before {
  content: '③';
}
.terms .circle > li:nth-child(4) > i:before {
  content: '④';
}
.terms .circle > li:nth-child(5) > i:before {
  content: '⑤';
}
.terms .circle > li:nth-child(6) > i:before {
  content: '⑥';
}
.terms .circle > li:nth-child(7) > i:before {
  content: '⑦';
}
.terms .circle > li:nth-child(8) > i:before {
  content: '⑧';
}
.terms .circle > li:nth-child(9) > i:before {
  content: '⑨';
}
.terms .circle > li:nth-child(10) > i:before {
  content: '⑩';
}
.terms .circle > li:nth-child(11) > i:before {
  content: '⑪';
}

.terms .num > li:nth-child(1) i:before {
  content: '1. ';
}
.terms .num > li:nth-child(2) i:before {
  content: '2. ';
}
.terms .num > li:nth-child(3) i:before {
  content: '3. ';
}
.terms .num > li:nth-child(4) i:before {
  content: '4. ';
}
.terms .num > li:nth-child(5) i:before {
  content: '5. ';
}
.terms .num > li:nth-child(6) i:before {
  content: '6. ';
}
.terms .num > li:nth-child(7) i:before {
  content: '7. ';
}
.terms .num > li:nth-child(8) i:before {
  content: '8. ';
}
.terms .num > li:nth-child(9) i:before {
  content: '9. ';
}
.terms .num > li:nth-child(10) i:before {
  content: '10. ';
}
.terms .num > li:nth-child(11) i:before {
  content: '11. ';
}
.terms .num > li:nth-child(12) i:before {
  content: '12. ';
}
.terms .num > li:nth-child(13) i:before {
  content: '13. ';
}
.terms .num > li:nth-child(14) i:before {
  content: '14. ';
}
.terms .num > li:nth-child(15) i:before {
  content: '15. ';
}

.terms .brac > li:nth-child(1) > i:before {
  content: '1)';
}
.terms .brac > li:nth-child(2) > i:before {
  content: '2)';
}
.terms .brac > li:nth-child(3) > i:before {
  content: '3)';
}
.terms .brac > li:nth-child(4) > i:before {
  content: '4)';
}
.terms .brac > li:nth-child(5) > i:before {
  content: '5)';
}
.terms .brac > li:nth-child(6) > i:before {
  content: '6)';
}
.terms .brac > li:nth-child(7) > i:before {
  content: '7)';
}
.terms .brac > li:nth-child(8) > i:before {
  content: '8)';
}
.terms .brac > li:nth-child(9) > i:before {
  content: '9)';
}
.terms .brac > li:nth-child(10) > i:before {
  content: '10)';
}
.terms .brac > li:nth-child(11) > i:before {
  content: '11)';
}
.terms .brac > li:nth-child(12) > i:before {
  content: '12)';
}
.terms .brac > li:nth-child(13) > i:before {
  content: '13)';
}
.terms .brac > li:nth-child(14) > i:before {
  content: '14)';
}
.terms .brac > li:nth-child(15) > i:before {
  content: '15)';
}
.terms .brac > li:nth-child(16) > i:before {
  content: '16)';
}
.terms .brac > li:nth-child(17) > i:before {
  content: '17)';
}
.terms .brac > li:nth-child(18) > i:before {
  content: '18)';
}
.terms .brac > li:nth-child(19) > i:before {
  content: '19)';
}
.terms .brac > li:nth-child(20) > i:before {
  content: '20)';
}
.terms .brac > li:nth-child(21) > i:before {
  content: '21)';
}
.terms .brac > li:nth-child(22) > i:before {
  content: '22)';
}
.terms .brac > li:nth-child(23) > i:before {
  content: '23)';
}
.terms .brac > li:nth-child(24) > i:before {
  content: '24)';
}
.terms .brac > li:nth-child(25) > i:before {
  content: '25)';
}

.terms .han > li:nth-child(1) > i:before {
  content: '가.';
}
.terms .han > li:nth-child(2) > i:before {
  content: '나.';
}
.terms .han > li:nth-child(3) > i:before {
  content: '다.';
}
.terms .han > li:nth-child(4) > i:before {
  content: '라.';
}
.terms .han > li:nth-child(5) > i:before {
  content: '마.';
}

.terms .hyphen > li > i:before {
  content: '-';
}
.terms .squ > li > i:before {
  content: '\e9c6';
  font-family: 'xeicon';
}
