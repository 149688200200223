@charset "UTF-8";

header {
  position: relative;
}
.top_logo a {
  display: block;
  padding: 30px 0;
}
.top_logo img {
  width: auto;
}

nav .menu > li {
  float: left;
  position: relative;
}
nav .menu > li:nth-child(1) {
  width: 20%;
}
nav .menu > li:nth-child(2) {
  width: 20%;
}
nav .menu > li:nth-child(3) {
  width: 18%;
}
nav .menu > li:nth-child(4) {
  width: 17%;
}
nav .menu > li:nth-child(5) {
  width: 25%;
}

nav .menu > li > a {
  display: block;
  position: relative;
  text-align: center;
  line-height: 100px;
}
nav .menu > li:hover > a {
  color: #0f3164;
}
nav .menu > li:hover span {
  border-bottom: 1px solid #0f3164;
}
header .lan {
  padding-top: 30px;
  padding-bottom: 30px;
}
header .lan select {
  float: right;
  width: 50%;
}

/*서브메뉴*/
nav .sub_menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 30;
  width: 100%;
}
nav .sub_menu a {
  display: block;
  padding: 5px 0;
  text-align: center;
}
nav .sub_menu li a:hover {
  color: #0f3164;
}
nav:hover .sub_menu {
  display: block;
  height: auto;
  padding: 20px 0;
}
nav .nav_on {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 0;
  opacity: 0;
  background: #fff;
  transition: all 0.5s;
}
nav:hover .nav_on {
  height: 150px;
  border-top: 1px solid #d2d8e3;
  opacity: 1;
}
header .col-xs-8 {
  position: initial;
}

/*모바일메뉴*/
.mob_nav {
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ababab;
  background: #fff;
}

html.open {
  overflow: hidden;
} /*메뉴가 열렸을때 본문이 스크롤 되지 않는다.*/
.mob_nav img {
  width: auto;
  height: 30px;
}
.mob_nav .mob_logo {
  display: block;
  text-align: left;
  padding: 10px;
}
.mob_btn {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  width: 50px;
  height: 50px;
  background: url("../img/common/mob_top.png") -50px -50px no-repeat;
} /*메뉴 버튼*/
#menu .close {
  width: 50px;
  height: 50px;
  padding: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
} /*닫기 버튼*/

#menu {
  width: 70%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0px;
  right: -70%;
  z-index: 999999;
  transition: All 0.2s ease;
  -webkit-transition: All 0.2s ease;
  -moz-transition: All 0.2s ease;
  -o-transition: All 0.2s ease;
} /*메뉴 영역*/

#menu .menu_log {
  padding: 14px 20px;
  text-align: center;
}
#menu .menu_log img {
  width: auto;
  height: 30px;
}

#nav-left > li > div {
  position: relative;
  padding: 16px 0 16px 10px;
  font-size: 1.2em;
  border-bottom: 0.7px solid #ebebeb;
}

#nav-left > li.open > div {
  position: relative;
  padding: 16px 0 16px 10px;
  font-size: 1.2em;
}
/*#nav-left > li > div:hover { color: #ef4147; border-bottom: 1px solid #e9e9e9; background: #e9e9e9; }*/
#nav-left .open_menu div#nav_left_menu:after {
  content: "\e941";
  position: absolute;
  right: 15px;
  font-family: "xeicon";
  width: 16px;
  height: 16px;
}

#nav-left .open_menu.open > div#nav_left_menu:after {
  content: "\e944";
  font-family: "xeicon";
}

/* .nav .open > a, .nav .open>a:focus, .nav .open>a:hover{ color: #fff; border-bottom: 1px solid #fd8a8a; background-color: #fa4b4b; } */

/* 모바일 서브 */
#nav-left > li > ul {
  display: block;
}
#nav-left > .open_menu > div > ul {
  display: block;
  border-bottom: 1px solid #ebebeb;
  background: #ebebeb;
}

#nav-left > .open_menu > div.react-slidedown {
  padding: 0;
}
#nav-left > .open_menu > div > ul > li > a {
  display: block;
  position: relative;
  padding: 10px 0 10px 20px;
  font-size: 1em;
}
#menu.open {
  right: 0px;
}

.page_cover {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: none;
}
.page_cover.open {
  display: block;
}

footer {
  position: relative;
  padding: 0 0 20px 0;
}
footer img {
  width: auto;
}
footer .lnk {
  padding: 15px 0;
  background: #ebebeb;
}
footer li {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
footer li a {
  display: inline-block;
  width: 30px;
}
footer .copy {
  font-size: 12px;
  color: #ababab;
}

footer .top {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 5px;
  width: 50px;
  height: 50px;
  line-height: 1em;
  padding: 9px 0;
  font-size: 14px;
  text-align: center;
  border-radius: 30px;
}
@media (max-width: 991px) {
  footer .con {
    padding-top: 1em;
  }
  footer .f_logo {
    width: 110px;
  }
  footer .top {
    padding: 12px 0;
    font-size: 12px;
  }
  footer li {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
}
