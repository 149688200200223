@charset "UTF-8";

.awssld {
	height: 480px;
}

.caption {
	bottom: 65px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    margin: 0;
    padding: 25px 25px 25px 30px;
    position: absolute;
    right: 75px;
    width: 295px;
    z-index: 1;
}